
.cardAgenciaMaritima:hover {

  box-shadow: 5px 10px 20px 1px rgba(255, 255, 255, 0.273) !important;
  transition: all 0.7s linear;
}
 .contenedorVideo{
  position: relative;
  padding-top: 56.25%;
}

 .react-player{
  position: absolute;
  top: 0;
  left: 0;
}
.status-OPERATIVO {
  background: #C8E6C9;
  color: #256029;
}
.status-CULMINADO {
  background: #FFCDD2;
  color: #C63737;
}

@media (prefers-reduced-motion: no-preference) {
  .Buque-china {
    animation: Buque-china-spin 2 20s  linear;
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
    animation-direction: alternate-reverse;
     transform: translate(340px, 90px);
    width: 50px;
    height: 30px;
    transition: all 0.7s linear;

  }

}
@keyframes Buque-china-spin {
  0% {
      transform: translate(20px,150px);
      width: 15px;
      height: 30px;
  }
   25% {
      transform: translate(-35px, 180px);

    }
   80% {
      transform: translate(-170px, 60px);

    }
    80.1% {
      transform: translate(450px, 60px);

    }
      100% {
      transform: translate(340px, 90px);
      width: 90px;
      height: 30px;
    }
  }

  /* estilos de la agenda */
  /* .rbc-toolbar button {
    color: rgba(255, 255, 255, 0.87) !important;
  }
  .rbc-off-range-bg {
    background: #909397 !important;
}
.rbc-off-range {
  color: rgba(0, 0, 0) !important;
}
.rbc-button-link {
  color: rgba(0, 0, 0) !important;
}
.rbc-day-bg {
  background: rgba(255, 255, 255, 0.87) !important;
}

.rbc-today {
  background-color: rgba(255, 255, 255, 0.87)  !important;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #ddd !important;

}
.rbc-rtl .rbc-header + .rbc-header {

  border-right: 1px solid rgb(253, 8, 8) !important;
}
.rbc-toolbar button {

  border: 1px solid rgb(255, 0, 0) !important;

}
.rbc-month-view {

  border: 1px solid rgb(255, 0, 0) !important;

}
.rbc-month-row + .rbc-month-row {
  border: 2px solid rgb(0, 0, 0) !important;
}
.rbc-day-bg + .rbc-day-bg {
  border: 1px solid rgb(0, 0, 0) !important;

}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {

  border: 1px solid rgb(0, 0, 0) !important;

} */

@import url("//fonts.googleapis.com/css?family=Roboto:300,400,500|Material+Icons");
@import url("//fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,700");


.high-intensity{
  background: #34cbef;
  display: block;
  height: 100%;
}

hr {
  border: none;
  border-bottom: 1px solid #ddd;
}
.rbc-calendar {
  margin-top: 20px;
  min-height: 600px;
  color: rgb(255, 255, 255);
  font: 300 1em/1.5 "Nunito", sans-serif;
}

.rbc-time-header > .rbc-row:nth-of-type(2) {
  display: none !important;
}

.rbc-time-content {
  border: none;
}

.rbc-timeslot-group {
  min-height: 50px;
}

.rbc-today {
  background: none;
}

.rbc-label {
  text-align: right;
}

.rbc-event {
  background: #eee;
  color: #000000 ;
  border-radius: 0;

}

.rbc-day-slot .rbc-event {
  border: 1px solid #fff;
}

.rbc-event.rbc-selected {
    background-color: #34cbef;
}

.rbc-time-view {
  border: none;
}

.rbc-time-gutter.rbc-time-column > .rbc-timeslot-group {
  border: none;
}

.rbc-label.rbc-header-gutter {
  border: none;
}
.rbc-time-header > .rbc-row > * + * {
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
}

.rbc-header {
  border-bottom: 1px solid #eee;
}

.rbc-time-header > .rbc-row:first-child {
  border-bottom: none;
}

.rbc-time-content .rbc-day-slot.rbc-time-column:last-child {
  border-right: 1px solid #eee;
}
.rbc-month-row  {
  border-top: 3px solid rgb(0, 0, 0) !important;
}
.rbc-month-view {
  border: 3px solid rgb(0, 0, 0) !important;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 3px solid rgb(0, 0, 0) !important;
}
.rbc-header{
  border-bottom: 0px solid rgb(0, 0, 0) !important;
}
.rbc-header + .rbc-header {
  border-left: 3px solid rgb(0, 0, 0) !important;
}
.rbc-day-bg {
  background: #d2d4d5 !important;
}

.rbc-today {
  background-color: #d2d4d5  !important;
}
.rbc-button-link {
  color: rgba(0, 0, 0) !important;
}
.rbc-toolbar button {
  color: rgba(255, 255, 255, 0.87) !important;
}
.rbc-event-content{
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  /* text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF; */
}
/* .scheduler-bg-table {
  background: #d2d4d5 !important;
} */
.header3-text {
  background: transparent !important;
  color: #000000 !important;
}
.scheduler-bg {
  background: #00a3f5 !important;
}
.scheduler-bg-table > tbody > tr > td {
  background: #d2d4d5 !important;
  border-color: #000000 ;
}
/* .event-start-resizer{
  background: #ff0000 !important;
} */
.event-item{
  color: #ffffff !important;
  font-weight: bold;
 font-size: 10px;

}
.datatable-rowexpansion-demo .product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-rowexpansion-demo .orders-subtable {
  padding: 1rem;
}

/* codigo para el trakin */


.skill-bars{
  padding: 5px 5px;
  width: 100%;
}
.skill-bars .bar{
  margin: 20px 0;
}
.skill-bars .bar:first-child{
  margin-top: 0px;
}
.skill-bars .bar .info{
  margin-bottom: 5px;
}
.skill-bars .bar .info span{
  font-weight: 500;
  font-size: 17px;
  opacity: 0;
  animation: showText 0.5s 1s linear forwards;
}
@keyframes showText {
  100%{
    opacity: 1;
  }
}
.skill-bars .bar .progress-line{
  height: 3px;
  width: 100%;
  background: #909397;
  position: relative;
  transform: scaleX(0);
  transform-origin: left;
  border-radius: 10px;
  
  animation: animate 1s cubic-bezier(1,0,0.5,1) forwards;
}
@keyframes animate {
  100%{
    transform: scaleX(1);
  }
}
.bar .progress-line span{
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  background: #00186b;
  animation: animate 1s 1s cubic-bezier(1,0,0.5,1) forwards;
}

.bar .progress-line.mysql span{
  width: 100%;
}
/* .progress-line span::before{
  position: absolute;
  content: "";
  top: -10px;
  right: 0;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-top-color: #000;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
  } */
.infosnippet-endpoint {
    left: auto !important;
    right: -5px;
    background-color: #fcc12a !important;
}
.infosnippet-startpoint, .infosnippet-endpoint {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -4px;
    left: -5px;
    border-radius: 20px;
    background-color: #248855;
    z-index: 10;
}
.text-endpoint {
    position: absolute;
   
    border-radius: 20px;
    /* background-color: #248855; */
    z-index: 10;
}
.text-endpoint{
  left: auto !important;
  right: -8px;
  /* background-color: #fcc12a !important; */
  /* width: 10px; */
  height: 10px;
  top: -26px;
  left: -5px;
  /* font-weight: 500; */
  font-size: 10px;

}
.progress-line span::before{
  content: "";
  position: absolute;
  top: -31px;
  margin: 0 auto;
  /* left: 0; */
  right: -12px;
  background: #2cadec;
  z-index: 10;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-top-right-radius: 0;
  transform: rotate(493deg);
  z-index: -1;
  }
.progress-line span::after{
  position: absolute;
  top: -26px;
  right: -9px;
  font-weight: 500;
  /* background: #000; */
  color: #fff;
  /* padding: 1px 8px; */
  font-size: 10px;
  border-radius: 3px;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
}

/* .progress-line span::after{
  position: absolute;
  top: -28px;
  right: 0;
  font-weight: 500;
  background: #000;
  color: #fff;
  padding: 1px 8px;
  font-size: 12px;
  border-radius: 3px;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
} */
@keyframes showText2 {
  100%{
    opacity: 1;
  }
}

/* .progress-line.mysql span::after{
  content: "75%";
} */
.datatable-style-demo .outofstock {
  font-weight: 700;
  color: #FF5252;
  text-decoration: line-through;
}

.datatable-style-demo .lowstock {
  font-weight: 700;
  color: #FFA726;
}

.datatable-style-demo .instock {
  font-weight: 700;
  color: #66BB6A;
}

.datatable-style-demo .row-accessories {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.datatable-style-demo .row-accessoriesViajeAux {
  background-color: rgba(238, 3, 3, 0.15) !important;
}
.datatable-style-demo .row-accessoriesargaViaje {
  background-color: rgba(27, 14, 214, 0.15) !important;
}